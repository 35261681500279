.heroSlider {
  width: 80%;
  /* height: 80vh; */
  /* max-height: 80vh; */
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 15px;
}

.bgImgStyle {
  width: 100%;
  height: 80vh;
  border-radius: 15px;
}

.textOverlay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 50px;
  font-size: 3rem;
  color: black;
  z-index: 10;
  cursor: pointer;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 50px;
  font-size: 3rem;
  color: black;
  z-index: 10;
  cursor: pointer;
}
