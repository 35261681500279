* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
}

.link {
  text-decoration: none;
  color: var(--white);
  font-size: 12px;
}

.link:hover {
  color: var(--blue);
}

:root {
  --light-gray: #dbdbdb;
  --gray: #b4b4b4;
  --dark-gray: #747474;
  --black: #0a0a0a;
  --white: #f8f8f8;
  --blue: #3645cf;
  --red: #1758d3;
  --delete: rgb(209, 0, 0);
}
