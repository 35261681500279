.rights{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: black;
    padding: 20px; 
    font-size: 13px;
    text-align: center;
}

.link{
    text-decoration: none;
    color: #fff;
    margin-top: 10px;
}

.link:hover{
    color: rgb(60, 60, 224);
}